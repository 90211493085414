import { cvIndustriesAPI, cvPersonSkillsAPI, cvRolesAPI } from 'api/CvAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ICv, IPersonSkillOrIndustryOrRole, IProject, IPersonSkillExperience, IEmployer } from 'types/cvsInterfaces'
import { IError } from 'types/error'
import withProfileCard from 'Components/reusable/HOC/withProfileCard'
import { getSkillsInitialData } from 'Components/reusable/DataContext/InitialData'
import { skillSchema } from 'Components/reusable/DataContext/ValidationSchema'
import SkillModal from './SkillModal'
import SkillsTable from './SkillsTable'
import SkillDetailsModal from './SkillDetailsModal'
import SkillMassUpdateModal from './SkillMassUpdateModal'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import SkillsItem from './SkillsItem'
import { useUser } from 'hooks'

/** @notExported */
interface ISkillsCardProps {
  /** Type of skill. */
  type: string
  /** Whether skills are editable. */
  editable: boolean
  /** CV object. */
  cv: ICv
  /** Function to set CV updated at. */
  setUpdatedAt: () => void
  /** Function to close item. */
  closeItem: () => void
  /** Function to set skills. */
  setItems: (skills) => void
  /** Function to set projects. */
  setProjects: (projects: IProject[]) => void
  /** Function to set employers. */
  setEmployers: (employers: IEmployer[]) => void
  /** Edited skill item. */
  editedItem: IPersonSkillOrIndustryOrRole | null
  /** Skills listing. */
  items: IPersonSkillOrIndustryOrRole[]
  /** Header text. */
  header: string
  /** Whether details are open. */
  detailsOpen: boolean
  /** Function to close details. */
  closeDetails: () => void
  /** Whether update is open. */
  updateOpen: boolean
  /** Function to close update. */
  closeUpdate: () => void
  /** Function to replace or add skill. */
  replaceOrAddItem: (newItem) => IPersonSkillOrIndustryOrRole[]
  /** Function to replace or add multiple skills. */
  replaceOrAddItems: (incomingItems) => IPersonSkillOrIndustryOrRole[]
  /** Function to update CV progress indicator. */
  updateProgress: () => void
}

/**
 * Profile skill management component.
 *
 * @returns Skill management component.
 * @notExported
 */
const SkillsCard: React.FC<ISkillsCardProps> = ({
  type,
  editable,
  cv,
  setUpdatedAt,
  closeItem,
  setItems,
  setProjects,
  setEmployers,
  editedItem,
  items,
  header,
  detailsOpen,
  closeDetails,
  updateOpen,
  closeUpdate,
  updateProgress,
}) => {
  const [selectedPersonSkills, setSelectedPersonSkills] = useState<IPersonSkillExperience[]>([])
  const [editableSkill, setEditableSkill] = useState<IPersonSkillOrIndustryOrRole>()
  const [backendError, setBackendError] = useState<IError>()
  const { t } = useTranslation()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))
  const { user } = useUser()

  let api

  switch (type) {
    case 'skill':
      api = cvPersonSkillsAPI
      break

    case 'industry':
      api = cvIndustriesAPI
      break

    case 'role':
      api = cvRolesAPI
      break

    default:
      break
  }

  const handleClose = (newItem: IPersonSkillOrIndustryOrRole) => {
    closeItem()
    if (newItem) {
      setUpdatedAt()
      updateProgress()
    }
  }

  const handleCloseUpdate = (updatedItems: IPersonSkillOrIndustryOrRole[]) => {
    closeUpdate()
    setUpdatedAt()
    updateProgress()
    setSelectedPersonSkills([])
    if (updatedItems.length) {
      const newItems = [...items]

      for (const item of updatedItems) {
        const index = newItems.findIndex(pskill => pskill.skillId === item.skillId)

        if (index > -1) {
          newItems[index] = item
        } else {
          newItems.push(item)
        }
      }

      setItems(newItems)
    }
  }

  const checkMassUpdateValidation = () => {
    let valid = selectedPersonSkills.length > 0 ? true : false
    for (let i = 0; i < selectedPersonSkills.length; i++) {
      if (!selectedPersonSkills[i].experienceMonthsGained) {
        valid = false
        break
      }
    }
    return valid
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  if (!user) {
    return null
  }

  return (
    <Grid>
      {mobileView ? (
        items.map(item => (
          <SkillsItem
            skill={item}
            editable={editable}
            onClick={() => {
              setEditableSkill(item)
            }}
            showTooltip={true}
            key={item.id}
            showLastUsed={true}
            type={type}
          />
        ))
      ) : (
        <SkillsTable
          editable={editable}
          setSkills={newSkills => setItems(newSkills)}
          setProjects={setProjects}
          setEmployers={setEmployers}
          type={type}
          handleClose={handleClose}
          api={api}
          items={items}
          cvId={cv.id}
          schema={skillSchema()}
          updateProgress={updateProgress}
          userProfile={cv.personId === user.Person?.id}
        />
      )}
      {(editedItem !== undefined || editableSkill !== undefined) && (
        <SkillModal
          key={(mobileView ? editableSkill?.id : editedItem?.id) ?? 'new'}
          cvId={cv.id}
          item={!mobileView ? editedItem : editableSkill}
          usedSkills={items}
          initialData={getSkillsInitialData(cv.id)}
          schema={skillSchema()}
          api={api}
          localeBase={`profile.${type}.modal`}
          header={header}
          maxWidth="xl"
          onClose={({ newItem }) => {
            setEditableSkill(undefined)
            handleClose(newItem)
          }}
          hideLanguageHelperText={true}
          skillsModal={true}
          type={type}
          noEscClose
          edit={editedItem === undefined}
        />
      )}
      {detailsOpen && (
        <SkillDetailsModal open={detailsOpen} onClose={closeDetails} header={t(`profile.${type}.levels`)} type={type} />
      )}
      {updateOpen && (
        <SkillMassUpdateModal
          onClose={handleCloseUpdate}
          selectedPersonSkills={selectedPersonSkills}
          setSelectedPersonSkills={personSkills => setSelectedPersonSkills(personSkills)}
          personSkills={items}
          cvId={cv.id}
          fullWidth={true}
          header={t(`profile.${type}.massUpdate.addTitle`)}
          allowSubmit={t('save')}
          type={type}
          valid={checkMassUpdateValidation()}
          noEscClose
        />
      )}
    </Grid>
  )
}

export default withProfileCard(SkillsCard)
